import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalService } from '../../core/services/modal.service';
import { OrderService } from '../../core/services/data/order.service';
import { UserService } from '../../core/services/data/user.service';
import { BooksService } from '../../core/services/data/books.service';
import { PurchaseProcessService } from '../../core/services/data/purchase-process.service';

const AUTHOR_DASHBOARD = '/author-dashboard';
const ORDER_CHECKOUT = 'order-checkout';
const FEATURES = 'features';
const HOME = 'home';

// Animations for the body of the modal during transitions
const slideInOutAnimation = [
	trigger('slideInOut', [
		// Define expected state values when the modal is open vs closed
		state('open', style({
			transform: 'translateX(0%)',
		})),
		state('closed', style({
			transform: 'translateX(100%)',
		})),
		// Define animation transition
		transition('closed => open', [
			animate('300ms cubic-bezier(0.35, 0, 0.25, 1)'),
		]),
		transition('open => closed', [
			animate('200ms cubic-bezier(0.35, 0, 0.25, 1)'),
		]),
	]),
];

@Component({
	selector: 'app-cart-modal',
	templateUrl: './cart-modal.component.html',
	styleUrls: ['./cart-modal.component.scss'],
	animations: slideInOutAnimation,
})
export class CartModalComponent implements OnInit, OnDestroy {
	@Input() modalId;

	@Input() maskClose;

	@Input() visible;

	public order;

	public orderTotalAmount = 0;

	public order$;

	public author$;

	public author;

	public root;

	public loading = false;

	private refresh$;

	constructor(
		private ms: ModalService,
		private os: OrderService,
		private us: UserService,
		private router: Router,
		private location: Location,
		private bs: BooksService,
		private purchaseProcessService: PurchaseProcessService,
	) { }

	ngOnInit() {
		this.root = window.location.origin;
		this.author$ = this.us.author$.subscribe(
			(data: any) => {
				this.author = data;
			},
		);
		this.order$ = this.os.orderStore$.subscribe((data: any) => {
			this.order = data;
			this.calculateOrderTotal();
		});
		this.refresh$ = this.os.orderRefresh.subscribe((orderId) => {
			this.loading = true;
			this.os.fetchOrderByID(orderId).subscribe((result: any) => {
				this.loading = false;
				if (result.success) {
					this.order = result.order;
					this.calculateOrderTotal();
				}
			});
		});
	}

	ngOnDestroy() {
		this.order$.unsubscribe();
		this.refresh$.unsubscribe();
	}

	calculateOrderTotal() {
		this.orderTotalAmount = this.os.calculateOrderTotal();
	}

	close() {
		this.os.setCartNotVisible();
		// If on the checkout screen after adding to cart, navigate home
		if (window.location.href.indexOf('checkout') !== -1) {
			let link = `/${HOME}`;
			if (this.author && this.author.authorLink) {
				link = `${AUTHOR_DASHBOARD}/${this.author.authorLink}/${HOME}`;
			}
			this.router.navigate(['/']).then(() => this.router.navigate([link]).then());
		}
	}

	continueShopping() {
		this.purchaseProcessService.clearDetails();
		let link = `/${FEATURES}`;
		this.os.setCartNotVisible();
		if (this.author && this.author.authorLink) {
			if (this.router.url === `${AUTHOR_DASHBOARD}/${this.author.authorLink}/promo-wizard`) {
				return;
			}
			link = `${AUTHOR_DASHBOARD}/${this.author.authorLink}/${FEATURES}`;
		}

		this.router.navigate(['/']).then(() => this.router.navigate([link]).then());
	}

	checkout() {
		let link = `/${FEATURES}/${ORDER_CHECKOUT}/${this.order._id}`;
		if (this.author && this.author.authorLink) {
			link = `${AUTHOR_DASHBOARD}/${this.author.authorLink}/${FEATURES}/${ORDER_CHECKOUT}/${this.order._id}`;
		}
		this.os.setCartNotVisible();
		/*
     * The built in routing service has a history of being buggy on this screen.
     * Primarily due to a known feature of same URL navigation where those requests are ignored.
     *
     * More info here:
     * https://stackoverflow.com/questions/54062232/what-is-the-use-of-onsameurlnavigation-property-in-angular-versions-greater-tha
     *
     * Because of these persistent issues, the router navigation has been replaced with the code below.
     */
		this.router.navigate([link]).then();
		// setTimeout(() => {
		// 	const { origin } = window.location;
		// 	window.location.replace(`${origin}${link}`);
		// }, 200);
	}
}
