import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as Rollbar from 'rollbar';
import { UserService } from './core/services/data/user.service';
import { AnalyticsService } from './core/services/analytics.service';
import { WebSocketService } from './core/services/web-socket.service';
import { FeaturedBooksService } from './core/services/data/featured-books.service';
import { BooksService } from './core/services/data/books.service';
import { OrderService } from './core/services/data/order.service';
import { ModalService } from './core/services/modal.service';
import { UtmTrackingService } from './core/services/utm-tracking.service';
import { ExperimentsService } from './core/services/experiments.service';

import { IUser } from './types/datatypes';

import { RollbarService } from './rollbar';

const topBarBlackList = [
	'/login',
	'/signup',
	'/forgot-password',
	'/reset-password',
	'/order-receipt',
	'confirm-account',
	'receipt',
	'/giveaways/',
];

declare const location: Location;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})

export class AppComponent implements AfterViewInit, OnInit {
	public modalVisible = false;

	public showTopBar = true;

	private employee = false;

	private order$;

	constructor(
		@Inject(RollbarService) private rollbar: Rollbar,
		private us: UserService,
		private as: AnalyticsService,
		private ws: WebSocketService,
		private fbs: FeaturedBooksService,
		private bs: BooksService,
		private router: Router,
		private os: OrderService,
		private ms: ModalService,
		private utms: UtmTrackingService,
		private experimentsService: ExperimentsService, // We want to initialize this right away so we have an established features route.
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.as.trackPage(event.urlAfterRedirects);

				const matchedBlackList = topBarBlackList.find((url) => this.router.url.includes(url));

				this.showTopBar = !matchedBlackList;
			}
		});

		if (topBarBlackList.includes(this.router.url)) {
			this.showTopBar = false;
		}
	}

	ngAfterViewInit(): void {
		const loadingMask = document.getElementById('loading-mask');

		if (loadingMask) {
			loadingMask.style.display = 'none';
		}
	}

	ngOnInit() {
		if (location.protocol === 'http:') {
			if (window.location.hostname.indexOf('localhost') === -1) {
				location.protocol = 'https:';
			}
		}
		// Trigger UTM parameter check on app initialization
		this.utms.checkForUTMParams();
		this.us.fetchUser().then((user: IUser) => {
			if (user) {
				this.as.identify(user.username);
				const supers = {
					'Logged In': true,
					Employee: false,
				};
				if (this.us.isAdminOrSuperAdmin()) {
					supers.Employee = true;
					this.employee = true;
					this.as.superProperty(supers);
				} else {
					this.as.superProperty(supers);
				}
				this.ws.setData('users', [user._id], false);
			}
		}, () => {
			this.as.superProperty({ 'Logged In': false, Employee: false });
		});
		this.order$ = this.os.cartVisibleStatus$.subscribe((isCartOpen: boolean) => {
			if (isCartOpen) {
				this.ms.open('cart-modal');
				this.modalVisible = true;
			} else {
				if (this.ms.modalCheck()) {
					// Wait for the animation to be triggered
					setTimeout(() => {
						this.ms.close('cart-modal');
					}, 200);
				}
				this.modalVisible = false;
			}
		});
	}

	closeArchivedAdvertNotice() {
		this.ms.close('archived-advert-notice');
	}
}
