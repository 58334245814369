<div class="background">
    <div class="flex login-body">
        <div class="img-aside flex">
            
        </div>
        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit($event)">
            <h3>Welcome Back! Login</h3>
            <app-input-form [id]="formComponents.email.key" [label]="formComponents.email.label"
            [type]="formComponents.email.type" [required]="formComponents.email.required" [form]="form"></app-input-form>
            <app-input-form [id]="formComponents.password.key" [label]="formComponents.password.label"
            [type]="formComponents.password.type" [required]="formComponents.password.required" [form]="form"></app-input-form>
            <div class="toggle-password-display" (click)="togglePasswordAttribute('password')">
                <fa-icon *ngIf="passwordHidden.password; else hide" [icon]="faEye"></fa-icon>
                <ng-template #hide>
                    <fa-icon [icon]="faEyeSlash"></fa-icon>
                </ng-template>
            </div>
            
            <div class="button-line">
            <button type="submit" class="btn block">Login</button>
            <div class="forgot">
                <a routerLink="/forgot-password" class="attempt-reset-password">Forgot Password?</a>
               </div>
            </div>
            <hr>
            <div class="signup-line">
                Not a Member? Create a Free Account 
            </div>
            <button class="btn invert block" routerLink="/signup">Sign Up</button>
        </form>
    </div>
</div>

<app-new-error-modal modalId="error-modal" [options]="errorOptions"></app-new-error-modal>
